.mainNav .nav-item.btnlast {
  background: #3f87f5;
  border: 1px solid #3f87f5;
  border-radius: 6px;
  padding: 0 0.5rem;
  margin-left: 0.9375rem;
}

.mainNav .nav-item.btnlast:hover {
  background-color: #275fb4;
}

.mainNav .nav-item.btnlast a {
  color: #fff;
}

.mainNav .nav-item.btnlast a:hover {
  color: #fff;
}

.mainNav .nav-item a {
  color: #fff;
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.mainNav .nav-item a:hover {
  color: #fff;
}

.mainNav .menu-cta:nth-last-child(2) a {
    color: #fff;
}

.mainNav .menu-cta:nth-last-child(1):hover a {
    color: #fff;
}

.mainNav .navbar-toggler span {
    background-color: #fff !important;
}

.btn-primary {
    background-color: #fff !important;
    color: #007bff !important;
    font-weight: bold !important;
    border-radius: 6px !important;
    border-color: transparent !important;
}

.btn-outline-primary {
    border-color: transparent !important;
}

.logo-black {
  display: none !important;
}

.logo-white {
  display: block !important;
}

.btn-blue {
    background-color: rgba(63, 135, 245, 1);
}

.text-blue {
    color: rgba(63, 135, 245, 1);

}

.text-gray {
    color: #8188A2;
    background-color: #F3F3F3;
    border-radius: 15px;
    width: fit-content;
}

.btn-white {
    background-color: rgba(255, 255, 255, 1);
}


.bg-dark-blue {
    background-color: #363F5E;
}

.text-48 {
    font-size: 48px;
}

.white-menu {
    background: linear-gradient(to right, rgba(54, 63, 94, 1), rgba(60, 109, 191, 1)) !important;
}

.subtitle {
    font-size: 32px;
    font-weight: 800;
    color: rgba(54, 63, 94, 0.96);
}

.badge-prev.active {
    border: 2px solid #3F87F5;
}

.badge-trab.active {
    border: 2px solid #EB5757;
}

.badge-civ.active {
    border: 2px solid #0E6F61;
}

.badge-penal.active {
    border: 2px solid #218FC0;
}

.badge-tribut.active {
    border: 2px solid #6B5ABF;
}


.badge-banc.active {
    border: 2px solid #b8922a;
}

#banner {
    background: linear-gradient(to right, rgba(54, 63, 94, 1), rgba(60, 109, 191, 1));
    text-align: center;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 71px;
}

#banner h1 span {
    border-bottom: 4px solid rgba(63, 135, 245, 1);
    border-radius: 2px;
}

#banner .btn {
    color: #FFF;
    border-radius: 4px;
    font-size: 18px;
    padding: 21px 32px;
    font-weight: 700;
}

#banner .btn-plans {
    border: 1px solid rgba(255, 255, 255, 0.24);

}

#calcs {
    position: relative;
    top: 71px;
}

.userStars {
    border-bottom: 1px solid #DDDDDD;
}

.userStars>div:first-child {
    border-right: 1px solid #DDDDDD;
}

.calculators .badge {
    width: fit-content;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.calculators .card {
    border: 1px solid #DDDDDD;
    box-shadow: 0px 6px 16px 0px rgba(32, 34, 36, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.14);
    color: rgba(54, 63, 94, 1);
}

.calculators .card .card-title {
    font-weight: 800;
}

.balloon {
    display: inline-block;
    background-color: rgba(133, 189, 90, 0.12);
    color: #18A450;
    padding: 7px 10px;
    border-radius: 15px;
    position: relative;
    font-weight: 900;
    text-align: center;
    margin-bottom: 7px;
    width: 25%;
}

.calculators .card {
    height: 224px
}

/* primeiro card-body dentro de card */
.card .description {
    min-height: 160px;
}

.card .link-calculators {
    height: 55px;
}

.link-calculators span {
    color: #007bff;
}

.link-calculators span:hover {
    color: #0056B3;
}

.financial h3 {
    font-size: 24px;
    color: rgba(54, 63, 94, 1);
    font-weight: 700;
}

.card .collapsed {
    background-color: #FFF;
}

.financial #accordion .card-header a {
    height: 50px;
    padding-right: 0;
    padding-left: 0;
}

.financial #accordion .card-header p {
    font-size: 16px;
    color: rgba(54, 63, 94, 1);
    font-weight: 600;
}


.financial .btn-blue {
    width: 40%;
}

#social-proof {
    position: relative;
    top: 71px;
}

#social-proof h2 {
    font-size: 24px;
}

#social-proof .blue-gradient {
    border-radius: 10px;
}

#courses {
    position: relative;
    top: 71px;
    /* Para poder posicionar o pseudo-elemento */
    background: linear-gradient(36.45deg, #363F5E 21.24%, #3C6DBF 78.76%);

}

#courses::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;
    height: 250px;
    /* Altura do degradê que cobrirá as últimas linhas */
    background: linear-gradient(to top, rgba(55, 69, 107, 0.9), rgba(60, 109, 191, 0));
    /* Cores do degradê */
    pointer-events: none;
    /* Para não interferir com cliques nos elementos abaixo */
}

#courses .bg-white {
    box-shadow: 0px 6px 16px 0px #2022241F, 0px 1px 2px 0px #00000024;
    min-height: 98px;
    border-radius: 15px;
}

#courses .course-name {
    color: #363F5E;

}

.last-row .last-card,
.last-row .penultimate-card {
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-height: 79px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.last-row .last-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
}

#faq {
    position: relative;
    top: 71px;
}

#faq .subtitle {
    color: rgba(54, 63, 94, 0.96);
}

#faq .faq-item {
    font-weight: 700 !important;
}

.financial .collapsed .toggleArrow,
#faq .collapsed .toggleArrow {
    transform: rotate(0deg);
    transition: transform 100ms ease-in-out;
    width: 16px;
    height: 8px;
    flex-shrink: 0;
}

.toggleArrow {
    transform: rotate(180deg);
    width: 16px;
    height: 8px;
}

.glide__arrow {
    box-shadow: none !important;
    text-shadow: none !important;
    background-color: rgb(54 63 94 / 25%) !important;
    border-radius: 50% !important;
    padding: 4px !important;
}

.footer {
    position: relative;
    top: 71px;
}

@media screen and (max-width: 1199px) {
    #courses .bg-white {
        min-height: 126px;
    }
}

@media screen and (max-width: 991px) {
  .mainNav.white-menu:not(.blue-menu-provisory) .nav-item .nav-link {
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
    #banner h1 {
        font-size: 44px;
    }

    #banner .btn {
        padding: 15px;
    }

    .userStars {
        text-align: center;
    }

    .userStars>div:first-child {
        border-right: none;
        margin-bottom: 10px;
    }

    .userStars>div:nth-child(2) {
        text-align: center
    }

    .calculators .card .card-title {
        font-size: 16px !important;
        margin-bottom: 5px;
    }

    .calculators .card .card-title,
    .calculators .card .card-text {
        padding-left: 1.2rem !important;
        padding-right: 1.2rem !important;
    }


    .card-text:last-child {
        font-size: 11px !important;
        margin-bottom: 0 !important;
    }

    .card .link-calculators {
        max-height: 48px;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        padding-top: 0.8rem !important;
    }

    .financial .balloon {
        width: 60%
    }

    .financial .col-md-4 h3 {
        text-align: center;
    }

    .financial .col-md-4 .d-flex.flex-column {
        text-align: center;
    }

    #courses .col-md-6:first-of-type .row {
        display: flex;
        justify-content: center;
        text-align: center;

    }

    .courses .courses-row {
        margin-bottom: 0 !important;
    }

    .courses .bg-white {
        margin: 0 auto;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    #courses::after {
        width: 100%;
        height: 100px;
    }

    #faq .subtitle {
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .last-row .penultimate-card {
        max-height: 79px;
        overflow: hidden;
    }
}

@media screen and (max-width: 575px) {
    #banner {
        height: 100%;
    }

    #courses .row {
        margin-left: 0;
        margin-right: 0;
    }

    #courses .bg-white {
        min-height: 98px;
        max-height: 98px;
    }

    .last-row .penultimate-card {
        min-height: 98px !important;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
    }
}